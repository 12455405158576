<template>
  <div class="item">
    <div class="image" :style="{ backgroundImage: 'url(' + image + ')' }" />
    <div class="details">
      <div class="d-flex flex-row">
        <div class="date flex-grow-1">
          {{ formattedDate }}
        </div>
        <div class="numberOfParticipants">
          <RoundIcon :size="20" icon-class="fal fa-users" />
          {{ numberOfParticipants }}/{{ maximumParticipants }}
        </div>
      </div>
      <div class="title">
        {{ title }}
      </div>
      <div class="d-flex flex-row">
        <div class="location flex-grow-1">
          {{ location }}
        </div>
        <div class="cost">
          <span v-if="cost !== '0.00'">{{ $n(cost, 'currency') }}</span>
          <span v-else>Gratis</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import RoundIcon from '@/components/RoundIcon'
export default {
  name: 'GroupEventItem',
  components: { RoundIcon },
  props: {
    title: {
      type: String,
      required: true
    },
    startDateTime: {
      type: String,
      required: true
    },
    location: {
      type: String,
      required: true
    },
    numberOfParticipants: {
      type: Number,
      required: true
    },
    maximumParticipants: {
      type: Number,
      required: true
    },
    locationLatLng: {
      type: String,
      required: false,
      default: undefined
    },
    cost: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: false,
      default: undefined
    }
  },
  computed: {
    formattedDate: function () {
      return moment(this.startDateTime).format('dd D MMM HH:mm')
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.item {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  &:hover {
    cursor: pointer;
  }
}
.date {
  font-size: 0.875rem;
  color: #999;
  text-transform: uppercase;
}
.title {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
}
.location {
  font-size: 0.875rem;
  color: #999;
}
.numberOfParticipants {
  font-size: 0.875rem;
}
.cost {
  font-size: 0.875rem;
}
.image {
  width: 4rem;
  height: 4rem;
  background-size: cover;
  border-radius: 0.5rem;
  flex: 0 0 auto;
  margin-right: 1rem;
}
.details {
  flex: 1 1 auto;
}
</style>
