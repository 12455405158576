<template>
  <div class="screen-group-detail-page has-tab-bar">
    <AppBar
      :title="group ? group.name : loading ? '' : 'Niet gevonden'"
      :show-back-button="true"
      back-button-path="/groups"
      :show-profile-button="false"
      :show-context-menu="contextMenuItems && contextMenuItems.length > 0"
      @toggle-context-menu="showContextMenu = !showContextMenu"
    ></AppBar>
    <SpinnerLoader v-if="loading"></SpinnerLoader>
    <template v-else-if="group">
      <div v-if="group.imageUrl" class="group-details-image-container">
        <div
          class="image-background"
          :style="{
            'background-image': `url(${group.imageUrl})`
          }"
        />
      </div>
      <div class="container">
        <template v-if="!group.hasAccess">
          <div class="members-container">
            <div class="member-avatars">
              {{ $tc('member', group.memberCount) }}
            </div>
            <a
              v-if="!group.isPendingMember"
              href="#"
              class="btn btn-green btn-round btn-right"
              @click.prevent="checkBeforeJoinGroup"
            >
              word lid
            </a>
            <button
              v-else
              class="btn btn-white-green-green btn-round btn-right"
              :disabled="true"
            >
              in afwachting
            </button>
          </div>
          <div class="members-more-info" />
          <div class="more-info">
            <p v-html="descriptionWithBr" />
            <div class="mt-4 alert alert-invi">
              Dit is een
              <span v-if="group.private">prive groep.</span>
              <span v-else-if="group.needsPermissionToAdd">
                besloten groep.
              </span>
              <template v-if="group.isPendingMember">
                We hebben de beheerder laten weten dat je graag toegevoegd wil
                worden, zodra deze jou toegang geeft ontvang je een bericht
              </template>
              <template v-else>
                Wil je lid worden, druk dan op de knop en wij sturen de
                beheerder een bericht of deze je wil toevoegen aan de groep
              </template>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="members-container">
            <div class="member-avatars">
              <GroupMemberItem
                v-for="member in acceptedMembers.slice(0, 5)"
                :key="member.id"
                :participant-id="member.participantId"
                :first-name="member.firstName"
                :last-name="member.lastName"
                :image-url="member.avatarUrl"
              />
            </div>
            <!-- <a
              v-if="participantMemberStatus === 'accepted'"
              href="#"
              class="btn btn-white-green-green btn-round btn-right"
              @click.prevent="routeToInviteNewMembers"
            >
              uitnodigen
            </a> -->
            <a
              v-if="!participantMemberStatus"
              href="#"
              class="btn btn-green btn-round btn-right"
              @click.prevent="checkBeforeJoinGroup"
            >
              word lid
            </a>
            <button
              v-if="participantMemberStatus === 'pending'"
              class="btn btn-white-green-green btn-round btn-right"
              :disabled="true"
            >
              in afwachting
            </button>
          </div>
          <div class="members-more-info">
            <a href="#" class="link" @click.prevent="routeToAllMembers">
              {{ $tc('member', acceptedMembers.length) }}
            </a>
            <span
              v-if="pendingMembers > 0 && participantIsOwner"
              class="badge"
              @click="routeToAllMembers"
            >
              {{ pendingMembers }} wachtend
            </span>
          </div>
          <div class="more-info">
            <p class="description" v-html="descriptionWithBr" />
          </div>
          <template>
            <div class="title-with-button">
              <h2 class="flex-1 my-6">Agenda</h2>
              <a
                v-if="
                  participantIsOwner ||
                  participantIsAdmin ||
                  (!needsPermissionToAdd &&
                    participantMemberStatus === 'accepted')
                "
                href="#"
                class="btn btn-green btn-round btn-small btn-float"
                @click.prevent="routeToCreateActivity"
              >
                nieuwe activiteit
              </a>
            </div>
            <div
              v-if="upcomingEvents && upcomingEvents.length"
              class="d-flex flex-column"
            >
              <GroupEventItem
                v-for="event in upcomingEvents.slice(0, 3)"
                :key="event.dateId"
                :title="event.name"
                :start-date-time="event.startDateTime"
                :end-date-time="event.endDateTime"
                :location="`${event.location.name}, ${event.location.city}`"
                :maximum-participants="event.maximumParticipants"
                :number-of-participants="event.numberOfParticipants"
                :cost="event.costFeeIncluded"
                :image="event.image"
                @click.native="
                  routeToEventView(
                    event.eventId,
                    event.dateId,
                    event.securityHash
                  )
                "
              />
              <a
                href="#"
                class="link ml-auto"
                @click.prevent="routeToAllEvents"
              >
                Alle activiteiten ({{ upcomingEvents.length }})
              </a>
            </div>
            <div v-else class="my-4">
              Er zijn voor deze groep binnenkort geen activiteiten gepland
            </div>
          </template>
          <template v-if="groupCards.length && creditcardsEnabled">
            <h2>
              {{ groupCards.length > 1 ? 'Strippenkaarten' : 'Strippenkaart' }}
            </h2>
            <card-item
              v-for="card in groupCards"
              :key="card.id"
              :card="card"
              :show-description="false"
              :show-buy-button="!participantHasCard && !participantIsOwner"
              :show-admin-button="participantIsOwner"
              @click-card="
                $router.push(`/cards/${card.id}?origin=/groups/${groupId}`)
              "
            />
          </template>
          <div
            v-else-if="
              (participantIsOwner || participantIsAdmin) && creditcardsEnabled
            "
            class="mt-4"
          >
            <h2 class="flex-1">Strippenkaart</h2>

            <div class="text-sm">
              Je hebt nog geen strippenkaart aangemaakt voor deze groep.
            </div>
            <a class="link mt-4 block" href="#" @click.prevent="createNewCard">
              Maak nieuwe strippenkaart
            </a>
          </div>
          <template
            v-if="
              groupCommentsEnabled &&
              (participantMemberStatus === 'accepted' || groupComments.length)
            "
          >
            <div class="title-with-button">
              <h2 class="flex-1 my-6">Prikbord</h2>
              <a
                v-if="participantMemberStatus === 'accepted' && !showAddMessage"
                href="#"
                class="btn btn-green btn-round btn-small btn-float"
                @click.prevent="showAddMessage = true"
              >
                nieuw bericht
              </a>
            </div>
            <template v-if="showAddMessage">
              <form class="mb-5" @submit.prevent="sendCommentForm">
                <div class="form-group">
                  <textarea
                    v-model="commentInput"
                    placeholder="Typ hier je bericht..."
                    class="form-control"
                  />
                </div>
                <button type="submit" class="btn btn-green btn-round btn-block">
                  plaats bericht
                </button>
                <a
                  href="#"
                  class="btn btn-white-green-green btn-round btn-block"
                  @click.prevent="showAddMessage = false"
                >
                  annuleer
                </a>
              </form>
            </template>
            <Messages v-if="groupComments" :items="groupComments" />
          </template>
          <template
            v-if="
              participantMemberStatus === 'accepted' && teamAndLeaguesEnabled
            "
          >
            <div class="title-with-button">
              <h2 class="page-title">Jouw teams</h2>
              <a
                href="#"
                class="btn btn-white-green-green btn-round btn-small btn-float"
                @click.prevent="routeToCreateTeam"
              >
                nieuw team
              </a>
            </div>
            <template
              v-if="group.participantTeams && group.participantTeams.length"
            >
              <GroupListItem
                v-for="team in group.participantTeams"
                :id="team.id"
                :key="team.id"
                :name="team.name"
                :image-url="team.imageUrl"
                :button-text="showAdminBtn && 'Beheer'"
                :show-arrow="!showAdminBtn"
                :show-pointer="!showAdminBtn"
                @button-action="routeToTeamAdminPage(team.id)"
                @click.native="
                  !showAdminBtn ? routeToTeamDetailPage(team.id) : null
                "
              />
            </template>
            <template v-else>
              <p>
                Je zit nog niet in een team. Meld je aan bij een bestaand team
                in deze groep of start een nieuwe om mee te kunnen doen met
                wedstrijden.
              </p>
            </template>
            <a
              v-if="group.teams && group.teams.length"
              href="#"
              class="link"
              @click.prevent="routeToAllTeams"
            >
              Bekijk alle teams ({{ group.teams.length }})
            </a>
            <h2 class="page-title">Competities</h2>
            <template v-if="group.leagues && group.leagues.length">
              <GroupListItem
                v-for="league in group.leagues"
                :id="league.id"
                :key="league.id"
                :name="league.name"
                :image-url="league.image"
                @click.native="routeToLeagueDetailPage(league.id)"
              />
            </template>
            <template v-else>
              <p>Er zijn momenteel geen actieve competities voor deze groep.</p>
            </template>
          </template>
          <a
            v-if="
              (participantIsAdmin || participantIsOwner) &&
              teamAndLeaguesEnabled
            "
            href="#"
            class="btn btn-white-green-green btn-block btn-round mt-3"
            @click.prevent="routeToGroupAdminLeagues"
          >
            beheer competities
          </a>
          <a
            v-if="
              (participantIsAdmin || participantIsOwner) &&
              teamAndLeaguesEnabled
            "
            href="#"
            class="btn btn-white-green-green btn-block btn-round mt-3"
            @click.prevent="routeToGroupAdminTeams"
          >
            beheer teams
          </a>
        </template>
      </div>
    </template>
    <div v-else class="container">
      <h1 class="page-title mt-4 mb-5">Oeps</h1>
      <p>We kunnen deze groep helaas niet vinden</p>
    </div>
    <TabBar selected="groups" />
    <ContextMenu
      v-if="contextMenuItems"
      :show="showContextMenu"
      :items="contextMenuItems"
      @close="showContextMenu = false"
      @click="(clickAction) => clickAction()"
    />
    <modal
      :show="showPaymentFeedbackModal"
      :show-close-icon="true"
      @close="
        () => {
          $router.push($route.path)
        }
      "
    >
      <template v-slot:header>Gelukt!</template>
      <p>
        Je hebt een strippenkaart aangeschaft voor de activiteiten van deze
        groep. Meld je nu makkelijk en snel aan.
      </p>
    </modal>
  </div>
</template>

<script>
import config from '@/utils/config'
import AppBar from '@/components/AppBar'
import TabBar from '@/components/TabBar'
import GroupEventItem from '@/components/GroupEventItem'
import GroupListItem from '@/components/GroupListItem'
import GroupMemberItem from '@/components/GroupMemberItem'
import Messages from '@/components/Messages'
import SpinnerLoader from '@/components/SpinnerLoader'
import ContextMenu from '@/components/ContextMenu'
import CardItem from '@/components/CardItem.vue'
import {
  GROUP_GET_DETAILS,
  GROUP_GET_UPCOMING_EVENTDATES,
  GROUP_JOIN,
  GROUP_LEAVE
} from '@/store/actions/group'
import {
  EVENT_ITEM_SET_ACTIVITY,
  EVENT_ITEM_SET_EVENT_TYPE,
  EVENT_ITEM_SET_GROUP,
  EVENT_CREATE_RESET
} from '@/store/actions/event'
import { CREDITCARD_GET_GROUP } from '@/store/actions/creditcard'
import { mapState, mapGetters } from 'vuex'
import { COMMENT_CREATE, COMMENT_GET_ALL } from '@/store/actions/comment'
import IconSettings from '@/components/icons/IconSettings'
import IconCredit from '@/components/icons/IconCredit'
import IconTrash from '@/components/icons/IconTrash'
import { CREDITCARD_GROUP_EVENTS } from '../../utils/constants'
import {
  CREDITCARD_NEW_RESET,
  CREDITCARD_NEW_UPDATE
} from '../../store/actions/creditcard'
import Modal from '../../components/Modal.vue'

export default {
  name: 'GroupsDetailPage',
  components: {
    AppBar,
    GroupEventItem,
    GroupListItem,
    GroupMemberItem,
    Messages,
    SpinnerLoader,
    TabBar,
    ContextMenu,
    CardItem,
    Modal
  },
  data: function () {
    return {
      commentInput: '',
      groupCommentsEnabled: config.featureFlags.groupComments,
      groupId: this.$route.params.id,
      showAddMessage: false,
      showAdminBtn: true,
      teamAndLeaguesEnabled: config.featureFlags.teamsAndLeagues,
      creditcardsEnabled: config.featureFlags.creditcards,
      showContextMenu: false,
      showPaymentFeedbackModal: false
    }
  },
  computed: {
    ...mapGetters(['authId']),
    ...mapState({
      group: (state) => state.group.groupDetails,
      loading: (state) => state.group.loading,
      joinedGroup: (state) => state.group.joinedGroup,
      upcomingEvents: (state) => state.group.upcomingEvents,
      groupComments: (state) => state.comment.commentList,
      groupCards: (state) => state.creditcard.groupCards
    }),
    action: function () {
      return this.$route.query.action
    },
    acceptedMembers: function () {
      return this.group.members.filter((member) => member.status === 'accepted')
    },
    participantIsAdmin: function () {
      return this.group.admins?.includes(parseInt(this.authId))
    },
    participantIsOwner: function () {
      return this.group.ownerId === parseInt(this.authId)
    },
    participantMemberStatus: function () {
      const member =
        this.group &&
        this.group.members &&
        this.group.members.find(
          (item) => item.participantId === parseInt(this.authId)
        )
      return member ? member.status : undefined
    },
    pendingMembers() {
      return this.group.members.filter((member) => member.status === 'pending')
        .length
    },
    contextMenuItems: function () {
      return (
        this.group && [
          ...(this.participantIsOwner || this.participantIsAdmin
            ? [
                {
                  key: 'settings',
                  text: 'Groepsinstellingen',
                  clickAction: this.routeToGroupAdmin,
                  icon: IconSettings
                },
                ...(this.creditcardsEnabled
                  ? [
                      this.groupCards.length
                        ? {
                            key: 'creditcard',
                            text: 'Beheer strippenkaart',
                            clickAction: this.routeToCreditCard,
                            icon: IconCredit
                          }
                        : {
                            key: 'creditcard',
                            text: 'Nieuwe strippenkaart',
                            clickAction: this.createNewCard,
                            icon: IconCredit
                          }
                    ]
                  : [])
              ]
            : []),
          ...(this.participantMemberStatus === 'accepted' &&
          !this.participantIsOwner
            ? [
                {
                  key: 'leave',
                  text: 'Verlaat deze groep',
                  clickAction: this.leaveGroup,
                  icon: IconTrash
                }
              ]
            : [])
        ]
      )
    },
    groupAndMemberStatusWatchable() {
      return this.group, this.participantMemberStatus, Date.now()
    },
    participantHasCard: function () {
      return this.groupCards.length ? !!this.groupCards[0].purchased_at : false
    },
    descriptionWithBr() {
      return (
        this.group?.description?.replace(/(?:\r\n|\r|\n)/g, '<br />') ??
        undefined
      )
    }
  },
  watch: {
    action(action) {
      this.showPaymentFeedbackModal = action === 'payment'
    },
    joinedGroup() {
      this.$store.dispatch(GROUP_GET_DETAILS, this.groupId)
    },
    groupAndMemberStatusWatchable() {
      if (
        this.group &&
        (this.participantMemberStatus === 'accepted' ||
          (!this.group.private && !this.group.needsPermissionToAdd))
      ) {
        this.$store.dispatch(GROUP_GET_UPCOMING_EVENTDATES, {
          groupId: this.groupId,
          skip: 0,
          limit: 0
        })
      }
    }
  },
  created() {
    this.$store.dispatch(GROUP_GET_DETAILS, this.groupId)
    this.$store.dispatch(CREDITCARD_GET_GROUP, { groupId: this.groupId })
    this.$store.dispatch(COMMENT_GET_ALL, {
      groupID: this.groupId,
      order: 'DESC'
    })
    if (this.action === 'join' && !this.participantMemberStatus) {
      this.joinGroup()
    } else {
      this.showPaymentFeedbackModal = this.action === 'payment'
    }
    if (
      this.group &&
      !this.upcomingEvents &&
      (this.participantMemberStatus === 'accepted' ||
        (!this.group.private && !this.group.needsPermissionToAdd))
    ) {
      this.$store.dispatch(GROUP_GET_UPCOMING_EVENTDATES, {
        groupId: this.groupId,
        skip: 0,
        limit: 0
      })
    }
  },
  methods: {
    routeToAllMembers: function () {
      this.$router.push(`/groups/${this.groupId}/members`)
    },
    routeToEventView: function (id, dateId, securityHash) {
      this.$router.push(
        `/events/${dateId}/${securityHash}?origin=groups/${this.groupId}`
      )
    },
    routeToCreateActivity: function () {
      this.$store.commit(EVENT_CREATE_RESET)
      this.$store.commit(EVENT_ITEM_SET_ACTIVITY, this.group.activityId)
      this.$store.commit(EVENT_ITEM_SET_EVENT_TYPE, this.group.eventTypeId)
      this.$store.commit(EVENT_ITEM_SET_GROUP, this.groupId)
      this.$router.push(`/events/new/date?origin=${this.$route.path}`)
    },
    routeToAllEvents: function () {
      this.$router.push(`/groups/${this.groupId}/events`)
    },
    routeToAllTeams: function () {
      this.$router.push(`/groups/${this.groupId}/teams`)
    },
    routeToGroupAdmin: function () {
      // this.$router.push(`/groups/admin/${this.groupId}`)
      // temporarily route directly to edit page
      this.$router.push(`${this.groupId}/edit`)
    },
    routeToGroupAdminLeagues: function () {
      this.$router.push(`/groups/admin/${this.groupId}?view=leagues`)
    },
    routeToGroupAdminTeams: function () {
      this.$router.push(`/groups/admin/${this.groupId}?view=teams`)
    },
    routeToCreateTeam: function () {
      this.$router.push(`/groups/${this.groupId}/team/new`)
    },
    routeToLeagueDetailPage: function (leagueId) {
      this.$router.push(`/groups/${this.groupId}/league/${leagueId}`)
    },
    routeToTeamDetailPage: function (teamId) {
      this.$router.push(`/groups/${this.groupId}/team/${teamId}`)
    },
    routeToTeamAdminPage: function (teamId) {
      this.$router.push(`/groups/${this.groupId}/team/${teamId}/edit`)
    },
    routeToCreditCard: function () {
      this.$router.push(`/cards/${this.groupCards[0].id}`)
    },
    checkBeforeJoinGroup: function () {
      if (!this.authId) {
        this.$router.push({
          path: '/login',
          query: { redirectTo: `/groups/${this.groupId}?action=join` }
        })
      } else {
        this.joinGroup()
      }
    },
    joinGroup: function () {
      this.$store.dispatch(GROUP_JOIN, {
        participantId: this.authId,
        groupId: this.groupId
      })
    },
    leaveGroup: function () {
      const member = this.group.members.find(
        (member) => member.participantId === parseInt(this.authId)
      )
      if (member) {
        this.$store.dispatch(GROUP_LEAVE, {
          memberId: member.id,
          groupId: this.groupId
        })
      }
    },
    sendCommentForm: function () {
      this.$store.dispatch(COMMENT_CREATE, {
        comment: this.commentInput,
        groupID: this.groupId
      })
      this.commentInput = ''
      this.showAddMessage = false
    },
    createNewCard() {
      this.$store.commit(CREDITCARD_NEW_RESET)
      const newCard = {
        type: CREDITCARD_GROUP_EVENTS,
        details: { groupId: parseInt(this.groupId) },
        source: 'group'
      }
      this.$store.commit(CREDITCARD_NEW_UPDATE, newCard)
      this.$router.push(`/cards/new`)
    }
  }
}
</script>

<style lang="scss" scoped>
.title-with-button {
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
}
.page-title {
  flex: 1;
}
.btn-float {
  width: 160px;
}
.btn-right {
  align-self: flex-end;
}
.group-details-image-container {
  position: relative;
  max-width: 640px;
  height: 150px;
  margin: 0 auto;
}
.page-title {
  margin: 2rem 0;
}
.members-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
}
.member-avatars {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.members-more-info {
  position: relative;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  div:not(:first-child) {
    margin-left: 1rem;
  }
}
.badge {
  right: 0;
  height: 1.5rem;
  border-radius: 1rem;
  line-height: 1rem;
  margin-left: 1rem;
  background-color: #dc3545;
  color: white;
  font-weight: 100;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}
.private-info {
  margin-bottom: 1rem;
}
::v-deep .description > a {
  color: #009b6d;
}
</style>
