<template>
  <div>
    <div v-for="item in items" :key="item.id" class="message mb-4">
      <GroupMemberItem
        :image-url="item.createdBy.avatarUrl"
        :name="item.createdBy.firstName"
        :participant-id="item.createdBy.id"
        size="medium"
        class="mr-3"
      />
      <div class="content">
        <div class="author-and-date">
          <div class="author">
            {{ `${item.createdBy.firstName} ${item.createdBy.lastName}` }}
          </div>
          <div class="date">
            {{ formatDate(item.created) }}
          </div>
        </div>
        <div class="comment">
          {{ item.comment }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import GroupMemberItem from './GroupMemberItem'
export default {
  name: 'Messages',
  components: {
    GroupMemberItem
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {}
  },
  watch: {},
  created: function () {},
  methods: {
    formatDate(datetime) {
      return moment(datetime).fromNow()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.message {
  display: flex;
  flex: 1;
}
.content {
  flex: 1;
}
.author-and-date {
  display: flex;
  @media (max-width: 480px) {
    flex-direction: column;
  }
}
.author {
  font-weight: 600;
  flex: 1;
}
.date {
  font-size: 0.875rem;
  color: #777;
  margin-bottom: 0.25rem;
}
</style>
